<template>
  <!-- 联系我们 -->
  <div class="contactUs">
    <div
      class="current-nav centerText"
      :style="{
        background: `url(${lxData.lxwmimg}) 100%/100% no-repeat`
      }"
    >
      <h1>{{ lxData.basicTitle }}</h1>
      <h2>{{ lxData.basictext }}</h2>
      <span>联系</span>
    </div>
    <div
      class="contactUs-nav"
      v-for="(item, idx) in navList"
      :key="idx"
      :class="'nav' + idx"
    >
      <div class="current-title">
        <span>{{ item.标题 }}</span>
        <p>
          {{ item.text }}
        </p>
      </div>
      <ul class="current-lxfs" v-if="idx == 0">
        <li v-for="(el, i) in lxfsList" :key="i">
          <div class="li_icon zz_all_img">
            <img :src="el.value" alt="" style="height:100%" />
          </div>
          <div class="li_text">
            <span>{{ el.key }}</span>
            <div class="li_text_nav" v-html="lxfs2List[i].value"></div>
          </div>
        </li>
      </ul>
      <div class="current-hzyx" v-if="idx == 1">
        <div class="current-hzyx-left">
          <el-input
            :placeholder="
              lxData.msList ? lxData.msList[0].value : '请输入您的信息'
            "
            v-model="formData.company"
            clearable
          >
          </el-input>
          <el-input
            :placeholder="
              lxData.msList ? lxData.msList[1].value : '请输入您的信息'
            "
            v-model="formData.phone"
            clearable
          >
          </el-input>
          <el-input
            :placeholder="
              lxData.msList ? lxData.msList[2].value : '请输入您的信息'
            "
            v-model="formData.username"
            clearable
          >
          </el-input>
          <el-input
            :placeholder="
              lxData.msList ? lxData.msList[3].value : '请输入您的信息'
            "
            v-model="formData.email"
            clearable
          >
          </el-input>
        </div>
        <div class="current-hzyx-right">
          <el-input
            type="textarea"
            :placeholder="lxData.miaoshu"
            v-model="formData.msgcontent"
            maxlength="1000"
            :autosize="{ minRows: 10, maxRows: 10 }"
            show-word-limit
            style="resize:none;"
          >
          </el-input>
          <el-button type="primary" @click="submitTo">提交需求</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { submitToData, getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      formData: {
        company: '',
        phone: '',
        username: '',
        email: '',
        msgcontent: ''
      },
      lxData: {},
      navList: [],
      lxfsList: [],
      lxfs2List: []
    }
  },
  methods: {
    getData () {
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.lxData = res.data.data.基础信息
          this.lxfsList = res.data.data.联系方式.relateArr
          this.lxfs2List = res.data.data.联系方式.relateArr2
          console.log(res.data.data.联系方式)
          this.navList = []
          this.navList.push(res.data.data.联系方式)
          this.navList.push(res.data.data.立刻联系)
        }
      })
    },
    submitTo () {
      submitToData(this.formData).then(res => {
        if (res.data.code == 1) {
          this.$message.success('提交成功')
          let obj = {
            company: '',
            phone: '',
            username: '',
            email: '',
            msgcontent: ''
          }
          this.formData = obj
          this.getData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.contactUs {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .contactUs-nav {
    padding-bottom: 80px;
    .current-lxfs {
      border-left: solid 1px #e6e6e6;
      box-sizing: border-box;
      width: 86%;
      margin: 0 auto;
      display: flex;
      li {
        width: 25%;
        border-right: solid 1px #e6e6e6;
        border-bottom: solid 1px #e6e6e6;
        border-top: solid 1px #e6e6e6;
        box-sizing: border-box;
        text-align: center;
        padding-bottom: 30px;
        .li_icon {
          height: 138px;
          margin-top: 45px;
        }
        .li_text {
          min-height: auto;
          font-size: 18px;
          color: #666666;
          margin-top: 40px;
          padding: 0 30px;
          line-height: 1.5;
          .li_text_nav {
            margin-top: 5px;
          }
        }
      }
    }
    .current-hzyx {
      width: 86%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .current-hzyx-left {
        width: 49%;
        .el-input {
          margin-bottom: 31px;
          /deep/input {
            padding: 30px;
            padding-left: 20px;
          }
        }
      }
      .current-hzyx-right {
        width: 49%;
        .el-textarea {
          /deep/textarea {
            padding: 20px;
          }
        }
        .el-button {
          width: 100%;
          margin-top: 33px;
          padding: 20px 0;
          span {
            font-size: 20px;
          }
        }
      }
    }
  }
  .nav1 {
    background-color: #f5f5f5;
  }
}
</style>
